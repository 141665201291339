import { createSelector } from '@reduxjs/toolkit';

const selectTimeTracker = (entities) => entities.timeTracker;

export const selectIsTimeTrackerLatest = createSelector(
  [selectTimeTracker],
  ({ isLatestVersion }) => isLatestVersion,
);

export const selectTimeTrackerFetching = createSelector(
  [selectTimeTracker],
  ({ isFetching }) => isFetching,
);

export const selectTimeTrackerAdding = createSelector(
  [selectTimeTracker],
  ({ isAdding }) => isAdding,
);
