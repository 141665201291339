import { isNumeric, toNum, round } from '@oliasoft-open-source/units';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { addHours } from '~views/time-tracker/utils/date/dateUtils';
import translations from '~src/internationalisation/translation-map.json';
import { itemFields } from '~src/enums/time-tracker';
import {
  ActivityCodes,
  addTaskOptions,
} from '~views/time-tracker/utils/consts';

const finishTimeOffset = (taskItem) => {
  if (!taskItem.estimate && !taskItem.actual) {
    return taskItem.afe;
  }
  if (taskItem.estimate && !taskItem.actual) {
    return taskItem.estimate;
  }
  if (taskItem.actual) {
    return taskItem.actual;
  }
};

const timeTracker = createSlice({
  name: 'timeTracker',
  initialState: {
    isAdding: false,
    isFetching: false,
    isLatestVersion: false,
    list: [],
    disabledFields: [
      itemFields.OPERATION,
      itemFields.ACTIVITY,
      itemFields.SECTION,
      itemFields.P90,
      itemFields.NPT,
      itemFields.WOW,
      itemFields.FINISH,
    ],
  },
  reducers: {
    itemsRequested: (timeTracker) => {
      timeTracker.isFetching = true;
    },
    itemsFetched: (timeTracker, action) => {
      timeTracker.isFetching = false;
      timeTracker.list = action.payload.operations;
      timeTracker.isLatestVersion = action.payload.isLatestVersion;
    },
    itemsRequestedFailed: (timeTracker) => {
      timeTracker.isFetching = false;
    },
    addTaskRequested: (timeTracker) => {
      timeTracker.isAdding = true;
    },
    taskAdded: (timeTracker, action) => {
      const { insertType, activityIndex, operationIndex, newItem } =
        action.payload;

      const insertPosition =
        insertType === addTaskOptions.BELOW ? activityIndex + 1 : activityIndex;
      timeTracker.list[operationIndex]?.activities.splice(
        insertPosition,
        0,
        newItem,
      );

      timeTracker.isAdding = false;
    },
    taskRemoved: (timeTracker, action) => {
      const { operationId, id } = action.payload;
      const operationIndex = timeTracker.list.findIndex(
        (item) => item.timeTrackerItemId === operationId,
      );

      if (operationIndex !== -1) {
        const taskIndex = timeTracker.list[operationIndex].activities.findIndex(
          (item) => item.timeTrackerItemId === id,
        );

        if (taskIndex !== -1) {
          delete timeTracker.list[operationIndex].activities[taskIndex];
          timeTracker.list[operationIndex].activities = timeTracker.list[
            operationIndex
          ].activities.filter((i) => i);
        }
      }

      timeTracker.isAdding = false;
    },
    updateTaskRequested: (timeTracker) => {
      timeTracker.isAdding = true;
    },
    taskUpdated: (timeTracker) => {
      timeTracker.isAdding = false;
    },
    listCleared: (timeTracker) => {
      timeTracker.list = [];
    },
    cellValueUpdated: (timeTracker, action) => {
      const { id, parentId, value, field } = action.payload;
      const operationIndex = timeTracker.list.findIndex(
        (item) => item.timeTrackerItemId === parentId,
      );

      if (operationIndex !== -1) {
        const taskIndex = timeTracker.list[operationIndex].activities.findIndex(
          (item) => item.timeTrackerItemId === id,
        );

        if (taskIndex !== -1) {
          timeTracker.list[operationIndex].activities[taskIndex][field] = value;
          if (field !== itemFields.ACTUAL_DEPTH && field !== itemFields.NAME) {
            let sum = 0;
            for (const activity of timeTracker.list[operationIndex]
              .activities) {
              const activityValue = isNumeric(activity[field])
                ? toNum(activity[field])
                : 0;
              sum += activityValue;
            }
            timeTracker.list[operationIndex][field] = round(sum, 2);
          }

          if (field === itemFields.ACTUAL || field === itemFields.ESTIMATE) {
            timeTracker.list.map((operation, idx) => {
              if (idx >= operationIndex) {
                operation.start =
                  idx > 0 ? timeTracker.list[idx - 1].finish : operation.start;
                operation.activities = operation.activities.map(
                  (activity, index) => {
                    if (index >= taskIndex || idx > operationIndex) {
                      activity.start =
                        index === 0
                          ? operation.start
                          : operation.activities[index - 1].finish;
                      activity.finish = addHours(
                        activity.start,
                        finishTimeOffset(activity),
                      );
                      return activity;
                    } else return activity;
                  },
                );
                operation.finish =
                  operation.activities[operation.activities.length - 1].finish;
                return operation;
              } else return operation;
            });
          }
          if (field === itemFields.ACTUAL_DEPTH) {
            const lowestDepth = timeTracker.list[operationIndex].activities
              .map((activity) => activity.actualDepth)
              .filter(Boolean)
              .slice(-1)[0];
            timeTracker.list[operationIndex][field] = lowestDepth;
          }
        }
      }
    },
    disabledFieldsUpdated: (timeTracker, action) => {
      timeTracker.disabledFields = action.payload;
    },
    updateStartTime: (timeTracker, action) => {
      timeTracker.list.map((operation, idx) => {
        operation.start =
          idx === 0 ? action.payload : timeTracker.list[idx - 1].finish;
        operation.activities = operation.activities.map((activity, index) => {
          activity.start =
            index === 0
              ? operation.start
              : operation.activities[index - 1].finish;
          activity.finish = addHours(
            activity.start,
            finishTimeOffset(activity),
          );
          return activity;
        });
        operation.finish =
          operation.activities[operation.activities.length - 1].finish;
        return operation;
      });
    },

    updateStartTimeRequested: (timeTracker) => {
      timeTracker.isAdding = true;
    },
    startTimeUpdated: (timeTracker) => {
      timeTracker.isAdding = false;
    },
    onTaskListReorder: (timeTracker, action) => {
      const { fromTaskId, toTaskId, parentId } = action.payload;

      const operationIndex = timeTracker.list.findIndex(
        (item) => item.timeTrackerItemId === parentId,
      );
      const fromTaskIndex = timeTracker.list[
        operationIndex
      ].activities.findIndex((item) => item.timeTrackerItemId === fromTaskId);
      const toTaskIndex = timeTracker.list[operationIndex].activities.findIndex(
        (item) => item.timeTrackerItemId === toTaskId,
      );
      const [min, max] = [fromTaskIndex, toTaskIndex].sort((a, b) => a - b);

      const copyList = timeTracker.list[operationIndex].activities.slice();
      copyList.splice(toTaskIndex, 0, copyList.splice(fromTaskIndex, 1)[0]);
      timeTracker.list[operationIndex].activities = copyList;

      timeTracker.list.map((operation, idx) => {
        if (idx === operationIndex) {
          // update time
          operation.activities = operation.activities?.map(
            (activity, index) => {
              if (index >= min && index <= max) {
                activity.start =
                  index === 0
                    ? operation.start
                    : operation.activities[index - 1].finish;
                activity.finish = addHours(
                  activity.start,
                  finishTimeOffset(activity),
                );
                return activity;
              } else return activity;
            },
          );
          // update plannedDepth
          operation.activities = operation.activities?.map(
            (activity, index) => {
              if (index >= min) {
                if (index === 0 && activity.activityCode !== ActivityCodes.DH) {
                  activity.plannedDepth = operation.depthFrom;
                  return activity;
                }
                if (activity.activityCode === ActivityCodes.DH) {
                  activity.plannedDepth = activity.depthTo;
                  return activity;
                } else {
                  activity.plannedDepth =
                    operation.activities[index - 1].plannedDepth;
                  return activity;
                }
              } else return activity;
            },
          );
          //  update actual depth
          const lowestDepth = operation.activities
            .map((activity) => activity.actualDepth)
            .filter(Boolean)
            .slice(-1)[0];
          operation.actualDepth = lowestDepth;
          return operation;
        } else return operation;
      });
    },
  },
});

export const {
  itemsRequested,
  itemsFetched,
  addTaskRequested,
  taskAdded,
  taskRemoved,
  taskUpdated,
  listCleared,
  updateTaskRequested,
  cellValueUpdated,
  disabledFieldsUpdated,
  updateStartTime,
  onTaskListReorder,
  updateStartTimeRequested,
  startTimeUpdated,
  itemsRequestedFailed,
} = timeTracker.actions;
export default timeTracker.reducer;

/**
 * Get all items
 *
 * @param projectId
 */
export const getTimeTrackerItems = (projectId) =>
  apiCallBegan({
    url: `/api/time-tracker/${projectId}`,
    method: 'GET',
    onStart: itemsRequested.type,
    onSuccess: (response) => ({
      type: itemsFetched.type,
      payload: response,
    }),
    onError: itemsRequestedFailed.type,
  });

/**
 * Add task
 *
 * @param data
 */
export const addTask = (data, { insertType, activityIndex, operationIndex }) =>
  apiCallBegan({
    url: '/api/time-tracker/task',
    method: 'POST',
    data,
    onStart: addTaskRequested.type,
    onSuccess: (response) => ({
      type: taskAdded.type,
      payload: { insertType, activityIndex, operationIndex, newItem: response },
    }),
  });

/**
 * Remove task
 *
 * @param id
 * @param operationId
 */
export const removeTask = (id, operationId) =>
  apiCallBegan({
    url: `/api/time-tracker/task/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: taskRemoved.type,
      payload: { id, operationId },
    }),
  });

/**
 * Update task
 *
 * @param id
 * @param data
 */
export const updateTask = (id, data) =>
  apiCallBegan({
    url: `/api/time-tracker/task/${id}`,
    method: 'PUT',
    data,
    onStart: updateTaskRequested.type,
    onSuccess: (response) => ({
      type: taskUpdated.type,
      payload: response,
    }),
  });

/**
 * Update time tracker start time
 *
 * @param projectId
 * @param startTime
 */
export const updateTrackerStartTime = (projectId, startTime) =>
  apiCallBegan({
    url: `/api/time-tracker/start-time/${projectId}`,
    method: 'PUT',
    data: { startTime: startTime.substr(0, startTime.length - 1) },
    onStart: updateStartTimeRequested.type,
    onSuccess: (response) => ({
      type: startTimeUpdated.type,
      payload: response,
    }),
  });

/**
 * Update time tracker start time
 *
 * @param projectId
 * @param startTime
 */
export const reorderTimeTrackerList = (projectId, data) =>
  apiCallBegan({
    url: `/api/time-tracker/reorder/${projectId}`,
    method: 'PUT',
    data,
    onStart: updateTaskRequested.type,
    onSuccess: (response) => ({
      type: taskUpdated.type,
      payload: response,
    }),
  });

/**
 * Add tasks
 *
 * @param data
 */
export const addTasks = (data) =>
  apiCallBegan({
    url: '/api/time-tracker/table',
    method: 'POST',
    data,
    onStart: addTaskRequested.type,
    onSuccess: (response) => {
      toast({
        message: {
          type: 'Success',
          content: i18n.t(translations.successfullImported),
        },
      });
      return {
        type: itemsFetched.type,
        payload: { operations: response, isLatestVersion: true },
      };
    },
    onError: (error) => {
      toast({
        message: {
          type: 'Error',
          content: error?.errorMessages?.join('; ') || error.message,
        },
      });
      return {
        type: taskUpdated.type,
      };
    },
  });

/**
 * Sync time tracker with latest simulation
 *
 * @param projectId
 */
export const sycnTimeTracker = (projectId) =>
  apiCallBegan({
    url: `/api/time-tracker/sync/${projectId}`,
    method: 'GET',
    onStart: itemsRequested.type,
    onSuccess: (response) => {
      if (!response?.operations.length) {
        toast({
          message: {
            type: 'Warning',
            content: i18n.t(translations.timeTracker_noOperationsFound),
          },
        });
      }
      return {
        type: itemsFetched.type,
        payload: response,
      };
    },
  });
