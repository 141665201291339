import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { round, toNum } from '@oliasoft-open-source/units';
import {
  Modal,
  Dialog,
  Button,
  Table,
} from '@oliasoft-open-source/react-ui-library';
import {
  addCampaign,
  campaignModalClosed,
  updateLearningCurve,
} from '~store/entities/campaigns/campaigns';
import { getDurationOrCost } from '~store/entities/simulations/simulations';
import { validatePercentage } from '~src/validation/common/validate-percentage';
import translations from '~src/internationalisation/translation-map.json';

const LearningCurveModal = ({
  campaign,
  learningCurveModalUpdated,
  updateLearningCurve,
  isAdding,
  probability,
}) => {
  const { t } = useTranslation();
  const [learningCurveValues, setLearningCurveValues] = useState([]);
  const errors = [];
  useEffect(() => {
    const data = campaign.estimates.map((estimate) => {
      const duration =
        getDurationOrCost(estimate.simulation, probability, 0) ?? 0;
      return {
        campaignsEstimatesId: estimate.campaignsEstimatesId,
        name: estimate.name,
        duration,
        adjustment: estimate.adjustment,
      };
    });
    setLearningCurveValues(data);
  }, [campaign]);

  const onClose = () => learningCurveModalUpdated(false);
  const onSubmit = () => {
    const withError = errors.includes(true);
    if (!withError) {
      updateLearningCurve(campaign.campaignId, learningCurveValues);
    }
  };
  const onChangeAdjustment = (value, i) => {
    const data = [...learningCurveValues];
    data[i].adjustment = toNum(value);
    setLearningCurveValues(data);
  };

  const rows = learningCurveValues.map((estimate, i) => {
    const error = t(validatePercentage(estimate.adjustment));
    errors[i] = !!error;

    return {
      cells: [
        { value: estimate.name },
        {
          value: estimate.duration,
        },
        {
          value: estimate.adjustment,
          type: 'NumberInput',
          disabled: !estimate.duration,
          error,
          onChange: (e) => onChangeAdjustment(e.target.value, i),
        },
        {
          value:
            !error && estimate.duration
              ? round(estimate.duration * (1 - estimate.adjustment / 100), 3)
              : estimate.duration,
        },
      ],
    };
  });

  const table = {
    headers: [
      {
        cells: [
          { value: t(translations.well) },
          { value: t(translations.duration) },
          { value: t(translations.adjustment) },
          { value: t(translations.adjusted) },
        ],
      },
      {
        cells: [
          { value: '' },
          { value: t(translations.days) },
          { value: '%' },
          { value: t(translations.days) },
        ],
      },
    ],
    rows,
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '600px',
          heading: `${t(translations.campaigns_learningCurve)} - ${
            campaign.name
          }`,
          content: <Table table={table} />,
          footer: (
            <>
              <Button
                label={t(translations.update)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isAdding } = entities.campaigns;

  return {
    isAdding,
  };
};

const mapDispatchToProps = {
  addCampaign,
  campaignModalClosed,
  updateLearningCurve,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LearningCurveModal);

export { Container as LearningCurveModal };
