import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Spacer,
  Divider,
  Select,
  Empty,
  Flex,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { probabilities } from '~src/enums/simulations';
import { SummaryChart } from '~views/campaigns/summary/summary-chart';
import { Kpi } from '~views/campaigns/kpi/kpi';
import { SimulationChartType } from '~src/enums/campaigns';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { SummaryTable } from '~views/campaigns/summary/summary-table';

const Summary = ({
  activeCampaign,
  kpiModalUpdated,
  primaryCurrency,
  activeCampaignTotal,
  isRecalculating,
  updateIsRecalculating,
  isGeneratingEstimates,
  setDataEntity,
  dataEntity,
  probability,
  setProbability,
}) => {
  const { t } = useTranslation();
  const [chartType, setChartType] = useState(SimulationChartType.TOTAL_DAYS);
  const [showTable, setShowTable] = useState(false);
  const probabilityOptions = Object.keys(probabilities).map((key) => ({
    label: key,
    value: probabilities[key],
  }));
  const chartTypeOptions = [
    {
      label: t(
        dataEntity === 0
          ? translations.campaigns_totalDays
          : translations.campaigns_totalCost,
      ),
      value: SimulationChartType.TOTAL_DAYS,
    },
    {
      label: t(translations.campaigns_metersPerDay),
      value: SimulationChartType.METERS_PER_DAY,
    },
  ];

  const controlsPortalId = `campaing-chart-controls-${chartType}`;

  return (
    <>
      {activeCampaign?.estimates?.length === 0 ? (
        <Flex>
          <Spacer height="300px" />
          <Empty
            height="100%"
            width="100%"
            text={t(translations.campaigns_noEstimatesSelected)}
          />
        </Flex>
      ) : (
        !isRecalculating && (
          <>
            <Spacer />
            <Flex>
              <Kpi
                kpiModalUpdated={kpiModalUpdated}
                campaign={activeCampaign}
              />
            </Flex>
            <Divider />
            <Flex justifyContent="space-between">
              <Flex>
                <Select
                  name="chartType"
                  width="auto"
                  options={chartTypeOptions}
                  small
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value === SimulationChartType.METERS_PER_DAY) {
                      setDataEntity(0);
                    }
                    setChartType(value);
                  }}
                  value={chartType}
                />
                <Spacer width="var(--padding)" />
                <ButtonGroup
                  items={[
                    {
                      key: 0,
                      icon: 'chart',
                    },
                    {
                      key: 1,
                      icon: 'table',
                    },
                  ]}
                  small
                  value={+showTable}
                  onSelected={(key) => {
                    setShowTable(!!key);
                  }}
                />
                <Spacer width="var(--padding)" />
                {!showTable && (
                  <Select
                    name="probability"
                    width="auto"
                    options={probabilityOptions}
                    small
                    onChange={(e) => setProbability(e.target.value)}
                    value={probability}
                  />
                )}
                <Spacer width="var(--padding)" />
                <ButtonGroup
                  items={[
                    {
                      key: 0,
                      label: t(translations.time),
                      value: 'time',
                    },
                    {
                      key: 1,
                      label: t(translations.cost),
                      value: 'cost',
                      disabled:
                        chartType === SimulationChartType.METERS_PER_DAY,
                    },
                  ]}
                  small
                  onSelected={(key) => {
                    setDataEntity(key);
                  }}
                  value={dataEntity}
                />
              </Flex>
              <Flex>
                <div id={controlsPortalId} />
              </Flex>
            </Flex>
            <Spacer />
          </>
        )
      )}
      {!showTable && (
        <SummaryChart
          campaign={activeCampaign}
          probability={probability}
          dataEntity={dataEntity}
          primaryCurrency={primaryCurrency}
          chartType={chartType}
          controlsPortalId={controlsPortalId}
          activeCampaignTotal={activeCampaignTotal}
          isRecalculating={isRecalculating}
          updateIsRecalculating={updateIsRecalculating}
          isGeneratingEstimates={isGeneratingEstimates}
        />
      )}
      {showTable && (
        <SummaryTable
          campaign={activeCampaign}
          probability={probability}
          chartType={chartType}
          dataEntity={dataEntity}
          primaryCurrency={primaryCurrency}
          activeCampaignTotal={activeCampaignTotal}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isRecalculating, isGeneratingEstimates } = entities.campaigns;
  return { isRecalculating, isGeneratingEstimates };
};

const Container = withErrorBoundary(connect(mapStateToProps)(Summary), {
  style: { position: 'absolute', top: '50px' },
});

export { Container as Summary };
