import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  ALT_UNITS,
  convertAndGetValue,
  fraction,
  getValue,
  isNumeric,
  isValueWithUnit,
  round,
  withUnit,
} from '@oliasoft-open-source/units';
import { Heading, Spacer, Table } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import {
  odDefaults,
  holeSizeDefaults,
  initialSchematic,
  addSchematic,
  cellValueUpdated,
  updateSchematic,
  reorderSchematic,
} from '~store/entities/well-details/well-details';
import { casingStringType } from '~src/enums/well-details';
import { validateNumber } from '~src/validation/common/validate-number';
import { autoSaveWait } from '~src/config/config';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { Sections } from '~src/enums/sections';

const initialUnits = {
  section: 'in',
  holeSize: 'in',
  top: 'm',
  bottom: 'm',
  toc: 'm',
  sectiontd: 'm',
  displacementFluidDens: 'sg',
};

const isEmptyDecimal = (number) =>
  number.endsWith('.') || (number.includes('.') && number.endsWith('0'));

const WellSchematicTable = ({
  odOptions,
  holeSizeOptions,
  schematic,
  wellDetailsId,
  addSchematic,
  isAdding,
  onClickDelete,
  cellValueUpdated,
  updateSchematic,
  reorderSchematic,
  isPageDisabled,
  unitSettings,
  sections,
  wellEvents,
  sectionTypes,
}) => {
  const { t } = useTranslation();
  const { project } = useParams();
  const [units, setUnits] = useState(initialUnits);

  const sectionTypeMap = new Map(
    sectionTypes.map((section) => [section.sectionTypeId, section.name]),
  );

  const casingSectionMap = new Map(
    sections.map((section) => [section.name, section.sectionId]),
  );

  const sectionTypesList = sectionTypes.map((sectionType) => ({
    label: sectionType.name,
    value: sectionType.sectionTypeId,
  }));

  const sectionTypeExcludeList = [
    Sections.Reservoir,
    Sections.PilotHole,
    Sections.LowerCompletion,
    Sections.MiddleCompletion,
    Sections.UpperCompletion,
    Sections.Custom,
  ];

  const excludedSectionIds = sections
    .filter((section) => sectionTypeExcludeList.includes(section.name))
    .map((section) => section.sectionId);

  const debounceUpdateSchematic = useRef(
    debounce(updateSchematic, autoSaveWait),
  );
  const [wellSchematic, setWellSchematic] = useState(schematic);

  useEffect(() => {
    setWellSchematic(schematic);
  }, [schematic]);
  useEffect(() => {
    setWellSchematic(schematic);
  }, [units]);
  useEffect(() => {
    const { length } = unitSettings;
    setUnits({
      ...initialUnits,
      top: length,
      bottom: length,
      toc: length,
      sectiontd: length,
    });
  }, [unitSettings]);

  const updateCasing = (casing, index, key, value) => {
    const updatedSchematic = [...wellSchematic];
    updatedSchematic[index] = {
      ...casing,
      [key]: value || '',
    };
    setWellSchematic(updatedSchematic);
  };

  const onChangeUnit = ({ target }) => {
    const { name, value } = target;
    setUnits({ ...units, [name]: value });
  };

  const isDifferentUnit = (key) => units[key] !== initialUnits[key];

  const rowsParams = [
    {
      key: 'sectionId',
      type: 'Select',
      name: t(translations.section),
      width: '140px',
      options: sections.map((section) => ({
        label: section.name,
        value: section.sectionId,
      })),
    },
    {
      key: 'sectionTypeId',
      type: 'Select',
      name: t(translations.type),
      width: '120px',
      options: sectionTypesList,
    },
    {
      key: 'holeSize',
      type: 'Select',
      name: t(translations.wellDetails_holeSize),
      quantity: 'diameters',
      width: '120px',
      options: holeSizeOptions,
      checkNonExistentValues: false,
    },
    {
      key: 'sectiontd',
      type: 'Input',
      name: t(translations.wellDetails_sectionTD),
      quantity: 'length',
      width: '120px',
    },
    {
      key: 'section',
      type: 'Select',
      name: t(translations.wellDetails_OD),
      quantity: 'diameters',
      width: '120px',
      options: odOptions,
    },
    {
      key: 'top',
      type: 'Input',
      name: t(translations.wellDetails_topMD),
      quantity: 'length',
      width: '120px',
    },
    {
      key: 'bottom',
      type: 'Input',
      name: t(translations.wellDetails_shoeMD),
      quantity: 'length',
      width: '120px',
    },
    {
      key: 'toc',
      type: 'Input',
      name: t(translations.wellDetails_TOCMD),
      quantity: 'length',
      width: '120px',
    },
    {
      key: 'displacementFluidDens',
      type: 'Input',
      name: t(translations.wellDetails_mudWeight),
      quantity: 'density',
      width: '120px',
    },
    {
      key: 'wellEvents',
      type: 'Select',
      name: t(translations.wellDetails_events),
      multi: true,
      width: '200px',
      options: wellEvents.map((wellEvent) => ({
        label: wellEvent.name,
        value: wellEvent.wellEventId,
      })),
    },
  ];
  const headerRow = rowsParams.map(({ name, width }) => ({
    value: name,
    width,
  }));

  const unitRow = rowsParams.map(({ quantity, key, width }) => {
    if (!quantity) return {};
    return {
      value: units[key],
      type: 'Select',
      width,
      options: ALT_UNITS[quantity].map((x) => ({ label: x, value: x })),
      name: key,
      onChange: onChangeUnit,
    };
  });

  const actions = [
    {
      label: t(translations.add),
      disabled: isAdding || isPageDisabled,
      primary: true,
      icon: 'add',
      onClick: () =>
        addSchematic({
          ...initialSchematic,
          wellDetailsId,
          projectId: project,
        }),
    },
  ];

  const isCellDisabled = (key, casing) => {
    return (
      ((key === 'toc' ||
        key === 'bottom' ||
        key === 'top' ||
        key === 'section') &&
        casing.sectionId === casingSectionMap.get(Sections.Reservoir)) ||
      (key === 'sectionTypeId' &&
        excludedSectionIds.includes(casing.sectionId)) ||
      ((key === 'toc' || key === 'holeSize') &&
        (casing.sectionId === casingSectionMap.get(Sections.UpperCompletion) ||
          casing.sectionId ===
            casingSectionMap.get(Sections.MiddleCompletion))) ||
      ((key === 'sectiontd' || key === 'holeSize') &&
        sectionTypeMap.get(casing.sectionTypeId) ===
          casingStringType.TIEBACK) ||
      (key === 'sectiontd' &&
        (casing.sectionId === casingSectionMap.get(Sections.MiddleCompletion) ||
          casing.sectionId === casingSectionMap.get(Sections.LowerCompletion) ||
          casing.sectionId === casingSectionMap.get(Sections.UpperCompletion)))
    );
  };

  const validateSectionTd = (value, bottomValue, casing) =>
    t(
      validateNumber(value || null) ||
        (!isCellDisabled('bottom', casing) && value >= bottomValue)
        ? null
        : translations.wellDetails_shouldBeBiggerThenOrEqualToBottomMD,
    );
  const validateTOCMD = (value, shoeMD, casing) =>
    t(
      validateNumber(value || null) ||
        (!isCellDisabled('bottom', casing) && value <= shoeMD)
        ? null
        : translations.wellDetails_shouldBeLessThanOrEqualToShoeM,
    );
  const validateTOPMD = (value, sectionTD, casing) =>
    t(
      validateNumber(value || null) ||
        (!isCellDisabled('sectiontd', casing) && value <= sectionTD)
        ? null
        : translations.wellDetails_shouldBeLessThanOrEqualToSectionTD,
    );
  const validateOD = (value, holeSize, casing) =>
    t(
      fraction(validateNumber(value || null)) ||
        (!isCellDisabled('holesize', casing) &&
          fraction(value) <= fraction(holeSize))
        ? null
        : translations.wellDetails_shouldBeLessThanOrEqualToHoleSize,
    );

  function getErrorForField(key, casing) {
    switch (key) {
      case 'sectiontd':
        if (
          sectionTypeMap.get(casing.sectionTypeId) ===
            casingStringType.CASING ||
          sectionTypeMap.get(casing.sectionTypeId) === casingStringType.LINER
        ) {
          return {
            error: validateSectionTd(
              isValueWithUnit(casing[key])
                ? convertAndGetValue(
                    withUnit(casing.sectiontd, units.sectiontd),
                    'm',
                  )
                : casing[key],
              isValueWithUnit(casing.bottom)
                ? convertAndGetValue(withUnit(casing.bottom, units.bottom), 'm')
                : casing.bottom,
              casing,
            ),
          };
        }
        break;

      case 'toc':
        if (
          sectionTypes.some(
            (section) => section.sectionTypeId === casing.sectionTypeId,
          )
        ) {
          return {
            warning: validateTOCMD(
              isValueWithUnit(casing[key])
                ? convertAndGetValue(withUnit(casing.toc, units.toc), 'm')
                : casing[key],
              isValueWithUnit(casing.bottom)
                ? convertAndGetValue(withUnit(casing.bottom, units.bottom), 'm')
                : casing.bottom,
              casing,
            ),
          };
        }
        break;

      case 'top':
        if (
          sectionTypeMap.get(casing.sectionTypeId) ===
            casingStringType.CASING ||
          sectionTypeMap.get(casing.sectionTypeId) === casingStringType.LINER
        ) {
          return {
            warning: validateTOPMD(
              isValueWithUnit(casing[key])
                ? convertAndGetValue(withUnit(casing.top, units.top), 'm')
                : casing[key],
              isValueWithUnit(casing.sectiontd)
                ? convertAndGetValue(
                    withUnit(casing.sectiontd, units.sectiontd),
                    'm',
                  )
                : casing.sectiontd,
              casing,
            ),
          };
        }
        break;
      case 'section':
        if (
          sectionTypes.some(
            (section) => section.sectionTypeId === casing.sectionTypeId,
          )
        ) {
          return {
            warning: validateOD(
              isValueWithUnit(casing[key])
                ? convertAndGetValue(
                    withUnit(casing.section, units.section),
                    'm',
                  )
                : casing[key],
              isValueWithUnit(casing.holeSize)
                ? convertAndGetValue(
                    withUnit(casing.holeSize, units.holeSize),
                    'm',
                  )
                : casing.holeSize,
              casing,
            ),
          };
        }
        break;

      default:
        return { error: t(validateNumber(Number(casing[key]) || null)) };
    }
  }

  const cellValue = (type, casing, key) => {
    const value = casing[key];
    if (type === 'Input') {
      if (
        value &&
        isNumeric(value) &&
        !isValueWithUnit(value) &&
        !isEmptyDecimal(String(value))
      ) {
        return convertAndGetValue(value, units[key], initialUnits[key]);
      } else if (isValueWithUnit(value)) {
        return getValue(value);
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  const tableRows = useMemo(
    () =>
      wellSchematic.map((casing, index) => {
        const cells = rowsParams.map(
          ({
            type,
            key,
            width,
            options,
            multi,
            checkNonExistentValues = true,
          }) => {
            const isDisabled = isCellDisabled(key, casing);
            const value = isDisabled ? '' : cellValue(type, casing, key);
            const cellOptions =
              options instanceof Function
                ? options(casing)
                : (options || []).map((option) => {
                    if (
                      (key === 'holeSize' || key === 'section') &&
                      isDifferentUnit(key)
                    )
                      return {
                        value: option.value,
                        label: round(
                          convertAndGetValue(
                            fraction(option.value),
                            units[key],
                            initialUnits[key],
                          ),
                        ),
                      };
                    return option;
                  });

            return {
              type,
              width,
              name: key,
              disabled: isDisabled || isPageDisabled,
              autoLayerWidth: type === 'Select',
              value,
              checkNonExistentValues,
              ...(type === 'Select' && multi ? { multi } : {}),
              ...(type === 'Input' || type === 'Select'
                ? getErrorForField(key, casing)
                : {}),
              options: cellOptions,
              ...(key === 'holeSize' || key === 'section'
                ? {
                    onCreate: (value) => {
                      const { schematicId, ...casingData } = casing;
                      cellValueUpdated({
                        id: schematicId,
                        value,
                        field: key,
                      });

                      debounceUpdateSchematic.current(
                        schematicId,
                        {
                          ...casingData,
                          [key]: value,
                        },
                        project,
                      );
                    },
                  }
                : {}),
              onChange: (e) => {
                let replaceComasToDotValue =
                  type === 'Select' && multi
                    ? e.target.value
                    : e.target.value.replace(/,/, '.');
                let replaceLocalValue = replaceComasToDotValue;
                if (type === 'Input') {
                  if (!isEmptyDecimal(replaceComasToDotValue)) {
                    replaceComasToDotValue = parseFloat(replaceComasToDotValue);
                    if (Number.isNaN(replaceComasToDotValue)) {
                      replaceComasToDotValue = null;
                    }
                  }

                  replaceLocalValue = replaceComasToDotValue;
                  if (
                    isNumeric(replaceComasToDotValue) &&
                    isDifferentUnit(key)
                  ) {
                    replaceLocalValue = withUnit(
                      replaceComasToDotValue,
                      units[key],
                    );
                    replaceComasToDotValue = convertAndGetValue(
                      replaceComasToDotValue,
                      initialUnits[key],
                      units[key],
                    );
                  }
                }
                cellValueUpdated({
                  id: casing.schematicId,
                  value: replaceComasToDotValue,
                  field: key,
                });
                if (
                  key === 'type' &&
                  (replaceComasToDotValue === casingStringType.TUBING ||
                    replaceComasToDotValue === casingStringType.TIEBACK)
                ) {
                  cellValueUpdated({
                    id: casing.schematicId,
                    value: null,
                    field: 'sectiontd',
                  });
                }
                if (
                  !validateNumber(replaceComasToDotValue) ||
                  type === 'Select'
                ) {
                  const updateData = {
                    ...casing,
                    [key]: replaceComasToDotValue,
                  };
                  if (
                    key === 'type' &&
                    (replaceComasToDotValue === casingStringType.TUBING ||
                      replaceComasToDotValue === casingStringType.TIEBACK)
                  ) {
                    updateData.sectiontd = null;
                  }
                  delete updateData.schematicId;
                  updateData.sectionTypeId = excludedSectionIds.includes(
                    updateData.sectionId,
                  )
                    ? null
                    : updateData.sectionTypeId;

                  debounceUpdateSchematic.current(
                    casing.schematicId,
                    updateData,
                    project,
                  );
                }
                updateCasing(casing, index, key, replaceLocalValue);
              },
            };
          },
        );

        return {
          cells,
          actions: [
            {
              label: t(translations.delete),
              icon: 'minus',
              onClick: (e) => onClickDelete(e, casing.schematicId),
              disabled: isPageDisabled,
            },
          ],
        };
      }),
    [wellSchematic, units, isPageDisabled],
  );

  const table = {
    fixedWidth: 'auto',
    headers: [{ cells: headerRow, actions }, { cells: unitRow }],
    rows: tableRows,
    footer: {},
    draggable: true,
  };

  return (
    <>
      <Heading>{t(translations.wellDetails_wellSchematic)}</Heading>
      <Spacer height="var(--padding-xs)" />
      <Table
        table={table}
        onListReorder={(params) => reorderSchematic(params, schematic)}
      />
      <Spacer />
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    wellDetails: { schematic, isAdding, sectionTypes },
    userSettings,
  } = entities;
  const odOptions = odDefaults.map((item) => ({ label: item, value: item }));
  const holeSizeOptions = holeSizeDefaults.map((item) => ({
    label: item,
    value: item,
  }));

  return {
    odOptions,
    holeSizeOptions,
    schematic,
    isAdding,
    unitSettings: userSettings.settings.units,
    sectionTypes,
  };
};

const mapDispatchToProps = {
  addSchematic,
  cellValueUpdated,
  updateSchematic,
  reorderSchematic,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(WellSchematicTable),
);

export { Container as WellSchematicTable };
