import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  Page,
  Loader,
  Spinner,
  Empty,
  Heading,
  Text,
  Spacer,
  Toggle,
  Button,
  Flex,
  Tabs,
  ProgressBar,
} from '@oliasoft-open-source/react-ui-library';
import {
  getCampaigns,
  campaignModalOpened,
  estimateModalUpdated,
  learningCurveModalUpdated,
  kpiModalUpdated,
  updateLearningCurveFlag,
  welldesignModalUpdated,
  addConceptDesigns,
  getSimulation,
  addSimulationStatus,
  getCampaignSimulation,
  getCampaignResults,
  campaignsCleanup,
  runCampaignTotalSimulation,
  updateIsRecalculating,
} from '~store/entities/campaigns/campaigns';
import { CampaignsList } from '~views/campaigns/campaigns.list';
import { DeleteCampaignModal } from '~views/campaigns/delete-campaign.modal';
import { CampaignModal } from '~views/campaigns/campaign.modal';
import { EstimateModal } from '~views/campaigns/estimate.modal';
import { Summary } from '~views/campaigns/summary/summary';
import translations from '~src/internationalisation/translation-map.json';
import { LearningCurveModal } from '~views/campaigns/learning-curve.modal';
import { KpiModal } from '~views/campaigns/kpi/kpi.modal';
import { selectPrimaryCurrency } from '~src/store/entities/company-settings/selectors';
import { CompareEstimatesDrawer } from '~views/compare-estimates/drawer/compare-estimates.drawer';
import { HierarchyDesignTableModal } from '~src/views/campaigns/hierarchy-table/hierarchy-design-table.modal';
import {
  useSocket,
  WEBSOCKET_EVENTS,
} from '~src/common/socket-contex/socket-context';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { CampaignTabIndex } from '~src/enums/campaigns';
import { probabilities, simulationStatuses } from '~src/enums/simulations';
import { useProgress } from '~src/common/progress/use-progress';
import { CampaignInputTab } from './campaign-tabs/campaign-input-tab';

const Campaigns = ({
  isFetching,
  campaignModalVisible,
  campaignModalOpened,
  estimateModalVisible,
  estimateModalUpdated,
  learningCurveModalVisible,
  learningCurveModalUpdated,
  kpiModalUpdated,
  kpiModalVisible,
  activeCampaign,
  getCampaigns,
  list,
  primaryCurrency,
  updateLearningCurveFlag,
  welldesignModalVisible,
  welldesignModalUpdated,
  designs,
  isAdding,
  countries,
  addConceptDesigns,
  conceptDesigns,
  getSimulation,
  addSimulationStatus,
  visibleEstimates,
  activeCampaignTotal,
  getCampaignSimulation,
  getCampaignResults,
  isFetchingCampaignResults,
  campaignsCleanup,
  runCampaignTotalSimulation,
  updateIsRecalculating,
  isRecalculating,
  isGeneratingEstimates,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const { socket } = useSocket();
  const [selectedId, setSelectedId] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [dataEntity, setDataEntity] = useState(0);
  const [probability, setProbability] = useState(probabilities.P10);
  const [deleteCampaignModalVisible, setDeleteCampaignModalVisible] =
    useState(false);
  const onClickDeleteCampaign = (id) => {
    setSelectedId(id);
    setDeleteCampaignModalVisible(true);
  };

  useEffect(() => {
    getCampaigns(company);
    socket.on(WEBSOCKET_EVENTS.notification_message, (m) =>
      addSimulationStatus(m.notification),
    );
    return () => {
      campaignsCleanup();
    };
  }, []);

  useEffect(() => {
    if (dataEntity === 1) {
      if (activeCampaign?.learningCurve === true) {
        updateLearningCurveFlag(activeCampaign.campaignId);
      }
    }
  }, [dataEntity]);

  useEffect(() => {
    Promise.all(
      visibleEstimates.map((estimate) => {
        if (
          estimate?.simulation?.status === 'Finished' &&
          !estimate?.simulation?.fetched
        ) {
          return getSimulation(estimate.projectId);
        }
        return null;
      }),
    );
  }, [visibleEstimates]);

  useEffect(() => {
    if (
      activeCampaignTotal?.simulation?.status === 'Finished' &&
      !activeCampaignTotal?.fetched
    ) {
      return getCampaignSimulation(activeCampaignTotal.campaignId);
    }
    return null;
  }, [activeCampaignTotal?.simulation]);

  const { campaignTotal, estimates } = activeCampaign || {};

  const shouldFetchResults =
    campaignTotal === undefined &&
    (!designs || designs.length === 0) &&
    estimates?.length > 0;

  const handleOnTabChange = (e) => {
    if (e.target.value === CampaignTabIndex.Result && shouldFetchResults) {
      setActiveTabIndex(+e.target.value);
      getCampaignResults(activeCampaign.campaignId);
    } else {
      setActiveTabIndex(+e.target.value);
    }
  };

  const showProgressBar = isRecalculating || isGeneratingEstimates;

  const progress = useProgress(
    activeCampaignTotal?.simulation?.status,
    simulationStatuses,
    updateIsRecalculating,
    isRecalculating,
    isGeneratingEstimates,
  );

  const getLoaderText = () => {
    if (activeCampaignTotal?.simulation?.message) {
      return activeCampaignTotal.simulation.message;
    }
    if (isGeneratingEstimates) {
      return t(translations.campaigns_generatingEstimates);
    }
    return t(translations.campaigns_runningCampaignSimulation);
  };

  const renderContent = () => {
    if (activeCampaign?.campaignTotal || isGeneratingEstimates) {
      return showProgressBar ? renderLoader() : renderSummary();
    }
    return renderEmpty();
  };

  const renderLoader = () => (
    <Loader text={getLoaderText()} theme="light" width="100%">
      <ProgressBar percentage={progress} showProgressColors width="300px" />
    </Loader>
  );

  const renderSummary = () => (
    <Summary
      activeCampaign={activeCampaign}
      kpiModalUpdated={kpiModalUpdated}
      primaryCurrency={primaryCurrency}
      activeCampaignTotal={activeCampaignTotal}
      updateIsRecalculating={updateIsRecalculating}
      setDataEntity={setDataEntity}
      dataEntity={dataEntity}
      probability={probability}
      setProbability={setProbability}
    />
  );

  const renderEmpty = () => (
    <>
      {isFetchingCampaignResults && (
        <Loader text={t(translations.fetching)} theme="light" cover>
          <Spinner dark />
        </Loader>
      )}
      <Empty
        height="100%"
        width="100%"
        text={t(
          translations.campaigns_noResultsAvailablePleaseRunASimulationToViewTheResults,
        )}
      />
    </>
  );

  return (
    <>
      <Page left={0} padding={false}>
        {(isFetching || isAdding) && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <CampaignsList
            campaigns={list}
            onClickDelete={onClickDeleteCampaign}
            campaignModalOpened={campaignModalOpened}
            setActiveTabIndex={setActiveTabIndex}
          />
          {activeCampaign ? (
            <>
              {activeTabIndex === CampaignTabIndex.Result && (
                <CompareEstimatesDrawer
                  estimates={activeCampaign.estimates}
                  filteredEstimates={[]}
                />
              )}
              <Column scroll padding>
                <Flex justifyContent="space-between" gap>
                  <Heading top>
                    {activeCampaign.name} <Spacer width="var(--padding)" />
                    <Text faint>
                      {activeCampaign.estimates?.length}{' '}
                      {t(translations.estimates)}
                    </Text>
                  </Heading>
                  <Flex gap alignItems="center">
                    {dataEntity === 0 && (
                      <>
                        {' '}
                        <Toggle
                          label={t(translations.learningCurve)}
                          checked={activeCampaign.learningCurve}
                          onChange={() =>
                            updateLearningCurveFlag(activeCampaign.campaignId)
                          }
                          noMargin
                        />
                        <Button
                          label={t(translations.edit)}
                          onClick={() => learningCurveModalUpdated(true)}
                          small
                        />
                      </>
                    )}
                    {activeCampaign?.campaignTotal && (
                      <Button
                        label={t(translations.campaigns_recalculate)}
                        onClick={() =>
                          runCampaignTotalSimulation(activeCampaign.campaignId)
                        }
                        small
                        disabled={isRecalculating}
                        loading={isRecalculating}
                      />
                    )}
                  </Flex>
                </Flex>
                <Tabs
                  value={activeTabIndex}
                  onChange={handleOnTabChange}
                  margin={0}
                  options={[
                    {
                      value: CampaignTabIndex.Input,
                      label: t(translations.input),
                      disabled: isGeneratingEstimates,
                    },
                    {
                      value: CampaignTabIndex.Result,
                      label: t(translations.result),
                    },
                  ]}
                />
                {activeTabIndex === CampaignTabIndex.Input && (
                  <CampaignInputTab
                    companyId={company}
                    activeCampaign={activeCampaign}
                    conceptDesigns={conceptDesigns}
                    visibleEstimates={visibleEstimates}
                    setActiveTabIndex={setActiveTabIndex}
                  />
                )}
                {activeTabIndex === CampaignTabIndex.Result && renderContent()}
              </Column>
            </>
          ) : (
            <Empty
              height="100%"
              width="100%"
              text={t(translations.campaigns_noComparisonSelected)}
            />
          )}
        </Row>
      </Page>
      {deleteCampaignModalVisible && (
        <DeleteCampaignModal
          setModalVisible={setDeleteCampaignModalVisible}
          selectedId={selectedId}
        />
      )}
      {campaignModalVisible && (
        <CampaignModal campaign={activeCampaign} companyId={company} />
      )}
      {estimateModalVisible && (
        <EstimateModal
          campaign={activeCampaign}
          estimateModalUpdated={estimateModalUpdated}
        />
      )}
      {learningCurveModalVisible && (
        <LearningCurveModal
          campaign={activeCampaign}
          learningCurveModalUpdated={learningCurveModalUpdated}
          probability={probability}
        />
      )}
      {kpiModalVisible && (
        <KpiModal kpiModalUpdated={kpiModalUpdated} campaign={activeCampaign} />
      )}
      {welldesignModalVisible && (
        <HierarchyDesignTableModal
          setModalVisible={welldesignModalUpdated}
          designs={designs}
          isFetching={isFetching}
          isAdding={isAdding}
          addConceptDesigns={addConceptDesigns}
          countries={countries}
          name={activeCampaign?.name}
          campaignId={activeCampaign?.campaignId}
          conceptDesigns={conceptDesigns}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    isFetching,
    list,
    campaignModalVisible,
    estimateModalVisible,
    learningCurveModalVisible,
    kpiModalVisible,
    welldesignModalVisible,
    designs,
    isAdding,
    countries,
    estimates,
    campaignTotal,
    isFetchingCampaignResults,
    isRecalculating,
    isGeneratingEstimates,
  } = entities.campaigns;
  const activeCampaign = list.find((item) => item.active);
  const primaryCurrency = selectPrimaryCurrency(entities);

  const activeEstimates = activeCampaign
    ? estimates.filter(
        (estimate) => estimate.campaignId === activeCampaign.campaignId,
      )
    : [];

  const visibleEstimates = activeEstimates.filter(
    (item) => item?.visible !== false,
  );
  const activeCampaignTotal =
    activeCampaign && campaignTotal
      ? campaignTotal?.find(
          (item) => item.campaignId === activeCampaign.campaignId,
        )
      : [];
  const conceptDesigns =
    activeCampaign?.designs?.map(
      ({
        accessed,
        country: countryName,
        countryCode,
        datasetid: datasetId,
        name,
        sort1: fieldName,
        sort2: siteName,
        sort3: wellName,
        sort4: wellboreName,
        updated,
        user_name: userName,
      }) => ({
        accessed,
        countryCode,
        countryName,
        datasetId,
        name,
        fieldName,
        siteName,
        wellName,
        wellboreName,
        updated,
        userName,
      }),
    ) ?? [];

  return {
    list,
    isFetching,
    activeCampaign,
    campaignModalVisible,
    estimateModalVisible,
    learningCurveModalVisible,
    kpiModalVisible,
    primaryCurrency,
    welldesignModalVisible,
    designs,
    isAdding,
    countries,
    conceptDesigns,
    visibleEstimates,
    activeCampaignTotal,
    isRecalculating,
    isGeneratingEstimates,
    isFetchingCampaignResults,
  };
};

const mapDispatchToProps = {
  getCampaigns,
  campaignModalOpened,
  estimateModalUpdated,
  learningCurveModalUpdated,
  kpiModalUpdated,
  updateLearningCurveFlag,
  welldesignModalUpdated,
  addConceptDesigns,
  getSimulation,
  addSimulationStatus,
  getCampaignSimulation,
  getCampaignResults,
  campaignsCleanup,
  runCampaignTotalSimulation,
  updateIsRecalculating,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Campaigns),
  { style: { position: 'absolute', top: '50px' } },
);

export { Container as Campaigns };
