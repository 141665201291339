import { useState, useEffect } from 'react';

interface SimulationStatus {
  INITIALISING: string;
  PREPARING_RESULTS: string;
  FINISHED: string;
}

export const useProgress = (
  status: string | undefined,
  simulationStatuses: SimulationStatus,
  updateIsRecalculating: (isRecalculating: boolean) => void,
  isRecalculating: boolean,
  isGeneratingEstimates: boolean,
): number => {
  const [progress, setProgress] = useState(0);
  const [progressIntervalId, setProgressIntervalId] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!status && !isRecalculating && !isGeneratingEstimates) return;

    const step = 1;
    const interval = 150;

    const startProgress = (maxProgress: number) => {
      if (progressIntervalId) return;
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = Math.min(prevProgress + step, maxProgress);
          if (newProgress >= maxProgress) {
            clearInterval(intervalId);
            setProgressIntervalId(null);
          }
          return newProgress;
        });
      }, interval);
      setProgressIntervalId(intervalId);
    };

    const stopProgress = () => {
      if (progressIntervalId) {
        clearInterval(progressIntervalId);
        setProgressIntervalId(null);
      }
    };

    if (isGeneratingEstimates && !isRecalculating) {
      startProgress(50);
    } else if (isRecalculating && !status) {
      startProgress(70);
    } else {
      switch (status) {
        case simulationStatuses.INITIALISING:
          startProgress(95);
          break;
        case simulationStatuses.PREPARING_RESULTS:
          stopProgress();
          setProgress(99);
          break;
        case simulationStatuses.FINISHED:
          stopProgress();
          updateIsRecalculating(false);
          setProgress(0);
          break;
        default:
          stopProgress();
          setProgress(0);
          break;
      }
    }

    return () => {
      stopProgress();
    };
  }, [status, simulationStatuses, isRecalculating]);

  return progress;
};
