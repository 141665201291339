import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
  Spacer,
  Heading,
  FormRow,
  useKeyboardEvent,
} from '@oliasoft-open-source/react-ui-library';
import { addTask } from '~store/entities/time-tracker/time-tracker';
import { Input, Select, UnitInput } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { addTaskSchemaValidator } from '~schemas/ajv-validators';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { selectTimeTrackerAdding } from '~src/store/entities/time-tracker/selectors';
import { selectUserSettings } from '~src/store/entities/user-settings/selectors';

const AddTaskModal = ({
  setTaskModalVisible,
  isAdding,
  addTask,
  newActivity,
  units,
  sections,
  operations,
  activities,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      activityId: newActivity?.activityId || null,
      actual: newActivity?.actual || null,
      actualDepth: newActivity?.actualDepth || null,
      afe: 0,
      finish: newActivity?.finish || null,
      name: newActivity?.name || '',
      npt: null,
      operationId: newActivity?.operationId || null,
      parentId: newActivity?.parentId || null,
      plannedDepth: newActivity?.plannedDepth || null,
      sectionId: newActivity?.sectionId || null,
      start: newActivity?.start || null,
      timeTrackerItemId: newActivity?.timeTrackerItemId || null,
      wow: null,
    },
    resolver: getResolver(addTaskSchemaValidator),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    const { insertType, activityIndex, operationIndex } = newActivity;
    await addTask(data, { insertType, activityIndex, operationIndex });
    setTaskModalVisible(false);
  });
  const onClose = () => {
    if (!isAdding) {
      setTaskModalVisible(false);
    }
  };
  useKeyboardEvent('Escape', onClose);

  const { sectionId, operationId, npt, wow, actual } = watch();

  const sectionOperations = operations
    .filter((operation) => operation?.sectionId === sectionId)
    .map((operation) => ({
      label: operation.name,
      value: operation.sectionsOperationId,
    }));

  const operationActivities = activities
    .filter((activity) => activity?.sectionsOperationId === operationId)
    .map((activity) => ({
      label: activity.name,
      value: activity.sectionsOperationActivityId,
    }));

  const prevSectionId = useRef(sectionId);
  const prevOperationId = useRef(operationId);

  useEffect(() => {
    if (prevSectionId.current !== sectionId) {
      setValue('operationId', null);
      setValue('activityId', null);
    } else if (prevOperationId.current !== operationId) {
      setValue('activityId', null);
    }
    prevSectionId.current = sectionId;
    prevOperationId.current = operationId;
  }, [sectionId, operationId, setValue]);
  useEffect(() => {
    if (npt + wow < actual) {
      clearErrors();
    }
  }, [npt, wow, actual]);

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.activityModel_newActivity),
          content: (
            <form>
              <FormRow>
                <Field label={t(translations.section)}>
                  <Select
                    options={sections}
                    name="sectionId"
                    control={control}
                    errors={errors}
                    width="200px"
                    disabled={true}
                  />
                </Field>
                <Field label={t(translations.operation)}>
                  <Select
                    options={sectionOperations}
                    name="operationId"
                    control={control}
                    errors={errors}
                    width="200px"
                    disabled={true}
                  />
                </Field>
              </FormRow>
              <Field label={t(translations.timeTracker_activityName)}>
                <Input name="name" control={control} errors={errors} />
              </Field>
              <Field label={t(translations.timeTracker_activityCode)}>
                <Select
                  options={operationActivities}
                  name="activityId"
                  control={control}
                  errors={errors}
                />
              </Field>
              <Heading>{t(translations.time)}</Heading>
              <Spacer height="var(--padding-sm)" />
              <FormRow>
                <Field label={t(translations.actual)}>
                  <UnitInput
                    name="actual"
                    unit={units.time}
                    control={control}
                    errors={errors}
                  />
                </Field>
                <Field label={t(translations.timeTracker_npt)}>
                  <UnitInput
                    name="npt"
                    unit={units.time}
                    control={control}
                    errors={errors}
                  />
                </Field>
                <Field label={t(translations.timeTracker_wow)}>
                  <UnitInput
                    name="wow"
                    unit={units.time}
                    control={control}
                    errors={errors}
                  />
                </Field>
              </FormRow>
              <Heading>{t(translations.depth)}</Heading>
              <Spacer height="var(--padding-sm)" />
              <FormRow>
                <Field label={t(translations.actual)}>
                  <UnitInput
                    name="actualDepth"
                    unit={units.length}
                    control={control}
                    errors={errors}
                  />
                </Field>
                <Field label={t(translations.planned)}>
                  <UnitInput
                    name="plannedDepth"
                    unit={units.length}
                    control={control}
                    errors={errors}
                  />
                </Field>
              </FormRow>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                loading={isAdding}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const { sections, operations, activities } =
    selectSectionsDataStructure(entities);
  const isAdding = selectTimeTrackerAdding(entities);
  const settings = selectUserSettings(entities);

  const sectionsOptions = sections.map((section) => ({
    label: section.name,
    value: section.sectionId,
  }));

  return {
    isAdding,
    units: settings.units,
    sections: sectionsOptions,
    operations,
    activities,
  };
};

const mapDispatchToProps = { addTask };
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(AddTaskModal),
  { isModal: true },
);

export { Container as AddTaskModal };
